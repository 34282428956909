import {
  FaChartBar,
  FaChartLine,
  FaArchive,
  FaEuroSign,
  FaLink,
  FaHandshake
} from 'react-icons/fa';

export const services = [
  {
    heading: 'Wirtschaftsprüfung',
    text: '<p>Gesetzlich vorgeschriebene Pflichtprüfungen führe ich in Kooperation mit einer mittelständisch geprägten Wirtschaftsprüfungsgesellschaft durch. Daneben betreue ich Sie auch im Rahmen der Durchführung freiwilliger Jahresabschlussprüfungen. Die Prüfungsabwicklung erfolgt in Übereinstimmung mit den Verlautbarungen und Regelungen des Instituts der Wirtschaftsprüfer (IDW), im Rahmen eines risikoorientierten Prüfungsansatzes unter Anwendung von standardisierten Prüfungsprogrammen und unter Wirtschaftlichkeitsgrundsätzen. Daneben führe ich Prüfungen von besonderen Vorgängen (z. B. Gründung, Verschmelzung, Spaltung, Sacheinlagen) sowie Liquidations- und Sonderprüfungen (z. B. nach MaBV) durch.</p>',
    excerpt: 'In Zusammenarbeit mit einer mittelständisch geprägten Wirtschaftsprüfungsgesellschaft führe ich gesetzlich vorgeschriebene Pflichtprüfungen durch.',
    icon: FaChartBar,
    link: '/leistungen#Wirtschaftsprüfung'
  },
  {
    heading: 'Steuerberatung',
    text: `<p>Nahezu jede unternehmerische Entscheidung wird von Steuerfragen beeinflusst. Aufgrund der permanenten Entwicklung des Steuerrechts wird auch die an den Zielen des Steuerpflichtigen ausgerichtete Beratung zunehmend komplexer. Wir übernehmen für Sie die klassische Steuerberatung, also die Erstellung Ihrer privaten oder betrieblichen Steuererklärungen, im betrieblichen Bereich auf Basis der von uns erstellten Jahresabschlüssen oder Einnahmen-Überschuss-Rechnungen. Dies betrifft u. a. die</p>
      <ul>
        <li>Einkommensteuererklärung</li>
        <li>Körperschaftsteuererklärung</li>
        <li>Gewerbesteuererklärung</li>
        <li>Umsatzsteuererklärung (einschl. der Umsatzsteuervoranmeldungen)</li>
        <li>Erklärung über die gesonderte und einheitliche Feststellung von Besteuerungsgrundlagen</li>
        <li>Erbschaft- und Schenkungsteuererklärung</li>
        <li>Lohnsteueranmeldungen</li>
        <li>Kapitalertragsteueranmeldungen</li>
      </ul>
      <p>Die Erstellung Ihrer Steuererklärungen erfolgt stets unter Einhaltung höchster Qualitätsvorgaben nach dem Vier-Augen-Prinzip mit der Zielsetzung, die für Sie minimalst mögliche Steuerbelastung zu erreichen. Daneben übernehmen wir auch Ihre steuerliche Gestaltungsberatung und die Vertretung gegenüber Finanzbehörden, Betriebsprüfern und den Finanzgerichten sowie dem Bundesfinanzhof.</p>`
    ,
    excerpt: 'Nahezu jede unternehmerische Entscheidung wird von Steuerfragen beeinflusst. Aufgrund der permanenten Entwicklung des Steuerrechts...',
    icon: FaChartLine,
    link: '/leistungen#Steuerberatung'
  },
  {
    heading: 'Beratung',
    text: `
      <p>Vor dem Hintergrund ständiger Veränderungen der steuerrechtlichen und wirtschaftlichen Rahmenbedingungen für Unternehmen und Privatpersonen muss eine gestaltende Beratung auf Ihre individuellen Gegebenheiten und Zielsetzungen ausgerichtet sein. In diesen Bereich fallen u. a. folgende Tätigkeiten:</p>
      <ul>
        <li>Betriebswirtschaftliche Beratung, z. B. Kostenanalysen, Rentabilitäts-, Liquiditäts- und Investitionsplanungen</li>
        <li>Steuerplanung bei der Umstrukturierung und dem Kauf und Verkauf von Unternehmen</li>
        <li>Private und betriebliche Nachfolgeplanung</li>
        <li>Steuerliche Gestaltungen im Rahmen der vorweggenommenen Erbfolge</li>
      </ul>
      <p>Als Wirtschaftsprüfer bin ich berufsrechtlich aufgrund der Berufsgrundsätze der Verschwiegenheit, Gewissenhaftigkeit, Neutralität und Wirtschaftlichkeit der Ansprechpartner für Unternehmensbewertungen, Gutachten und wirtschaftliche Beratungen bei Unternehmenskäufen und -verkäufen, Anteilsübertragungen sowie bei der Durchführung von Due-Dilligence-Prüfungen.</p>
    `,
    excerpt: 'Vor dem Hintergrund ständiger Veränderungen der steuerrechtlichen und wirtschaftlichen Rahmenbedingungen für Unternehmen...',
    icon: FaHandshake,
    link: '/leistungen#Beratung'
  },
  {
    heading: 'Rechnungswesen',
    text: `
      <p>Dieser Bereich umfasst die klassische Aufgabe der Erfassung aller Geschäftsvorfälle eines Unternehmens/Betriebes und der Abbildung in einem geeigneten Berichtswesen.
      Hierzu gehören die:</p>
      <ul>
        <li>Erstellung der monatlichen Finanzbuchhaltung, Anlagenbuchhaltung und Lohnbuchhaltung</li>
        <li>Erstellung monatlicher betriebswirtschaftlicher Auswertungen zur Information des Unternehmers zur Kontrolle und Planung der innerbetrieblichen Leistungsindikatoren</li>
        <li>Erstellung aller vorgeschriebenen Meldungen (z. B. Umsatzsteuer-Voranmeldungen, ZM-Meldungen, Lohnsteueranmeldungen)</li>
      </ul>
      <p>Selbstverständlich unterstützen wir Sie – soweit Sie Ihre Buchhaltung selbst abwickeln – auch in Fragen des Rechnungswesens und der Personalwirtschaft.</p>
    `,
    excerpt: 'Dieser Bereich umfasst die klassische Aufgabe der Erfassung aller Geschäfts-vorfälle eines Unternehmens / Betriebes.',
    icon: FaEuroSign,
    link: '/leistungen#Rechnungswesen'
  },
  {
    heading: 'News',
    text: null,
    excerpt: 'In unseren News halten wir unsere aktuellen und archivierten Informations-briefe für Sie bereit.',
    icon: FaArchive,
    link: '/news'
  },
  {
    heading: 'Links',
    text: null,
    excerpt: 'Hier finden Sie Links zu weiterführenden und interessanten Information rund um das Thema Steuerrecht.',
    icon: FaLink,
    link: '/links'
  },
];

export const links = [
  {
    heading: 'Bundesministerium für Finanzen',
    link: 'https://www.bundesfinanzministerium.de/'
  },
  {
    heading: 'Bundessteuerberaterkammer',
    link: 'https://www.bstbk.de/'
  },
  {
    heading: 'Bundeszentralamt für Steuern',
    link: 'https://www.bzst.de/DE/Home/home_node.html'
  },
  {
    heading: 'Finanzamt Schwelm',
    link: 'https://www.finanzverwaltung.nrw.de/de/eckdaten/kontaktdaten-schwelm'
  },
  {
    heading: 'Wirtschaftsprüferkammer',
    link: 'https://www.wpk.de/'
  }
];