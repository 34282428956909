import React         from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { FaLink }    from 'react-icons/fa';
import styled        from '@emotion/styled';

import Link from '@interness/web-core/src/components/elements/Link/Link';

import { links } from '../data/content'

const StyledBox = styled(Box)`
  border-left: 5px solid ${props => props.theme.brand_color};
  padding: 0 10px 0 20px;

  h3 {
    word-break: break-word;
    overflow-wrap: break-word;
  }

  svg, h3 {
    display: inline-block;
  }

  svg {
    fill: ${props => props.theme.brand_color};
    margin-right: 10px;
  }

  margin-bottom: 50px;
`;

const Links = () => {
  return (
    <Flex flexWrap="wrap" justifyContent="center">
      {links.map(link => <StyledBox width={['100%', '50%', '33%', '33%']} key={link.heading}>
        <div><FaLink/><h3>{link.heading}</h3></div>
        <Link to={link.link}>Mehr erfahren</Link>
      </StyledBox>)}
    </Flex>
  )
};

export default Links;
